import styled from 'styled-components';

const fonts = new FontFace('Poppins', '../../fonts/Poppins');

export const Title = styled.h1`
margin: 0.5rem 0rem;
font-weight: 700;
font-size: 1em;
text-align: center;
`;

export const Paragrafo = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
  font-size: 0.9em;
  font-weight: 500;
  font-style: italic;
  font-family: "Poppins";
`;

export const Secao = styled.div`
  background-color: white;
  width: 45%;
  height: 30rem;
  outline: 2px solid rgb(230, 230, 230);
  margin: 0.5rem;


  @media (max-width: 1600px) {
    width: 70%;
    height: 30rem;
  }

  @media (max-width: 1250px) {
    width: 30rem;
    height: 30rem;
    margin: 1rem;
  }

  @media (max-width: 1015px) {
    width: 90%;
    height: 30rem;
  }

  @media (max-width: 380px) {
    height: 40rem;
  }

  @media (max-width: 300px) {
    height: 50rem;
  }

  p {
    text-align: justify;
    padding: 0.5rem;
    font-size: 0.9em;
    font-weight: 500;
    font-style: italic;
    font-family: "Poppins";
  }
`;

export const Videos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
