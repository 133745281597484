import React from 'react';
import {
  FaHome, FaVideo,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cieeImage from '../../images/Logo CIEE (simplificada branca).png';

import { Nav, Menu } from './styled';

export default function Header() {
  const botaoClicado = useSelector((state) => state.example.botaoClicado);

  return (
    <Nav>
      <div>
        <img src={cieeImage} alt="Logo do CIEEMG" />
      </div>
      <Menu>
        <Link to="/cursos" alt="Cursos">
          <FaVideo size={24} />
        </Link>
        <Link to="/" alt="Home">
          <FaHome size={24} />
        </Link>
      </Menu>
    </Nav>
  );
}
