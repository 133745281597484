import React from 'react';
import Iframe from 'react-iframe';

import { Container } from '../../styles/GlobalStyles';
import {
  Title, Paragrafo, Secao, Videos,
} from './styled';

export default function Cursos() {
  return (
    <Container>
      <Videos>
        <Secao>
          <Iframe
            url="https://www.youtube-nocookie.com/embed/xKM0Mj40QlM?si=HUeWkQiqT2vtjR5m"
            width="100%"
            height="60%"
            id=""
            className=""
            display="block"
            position="relative"
          />
          <Title>Institucional</Title>
          <Paragrafo>
            Vídeo institucional do CIEE/MG
          </Paragrafo>
        </Secao>

        <Secao>
          <Iframe
            url="https://www.youtube-nocookie.com/embed/3QVAKL1Hy-Y?si=6mfdz1Iu8ZuIcH9Q"
            width="100%"
            height="60%"
            id=""
            className=""
            display="block"
            position="relative"
          />

          <Title>Leis Gerais de Proteção de Dados</Title>
          <p>
            O Treinamento de LGPD é baseado nos conceitos principais da Lei
            Geral de Proteção de Dados Pessoais,
            sendo assim, seu objetivo principal é garantir a transparência,
            segurança, diretrizes objetivas sobre a coleta, o processamento
            e o armazenamento de dados pessoais.
          </p>
        </Secao>
      </Videos>

    </Container>
  );
}
