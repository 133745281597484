import styled from 'styled-components';
import { primaryColor } from '../../config/colors';

export const Nav = styled.nav`
background: #004892;
padding: 5px 1px 5px 1px;
display: flex;
justify-content: space-between;

a {
  color: rgb(230, 230, 230);
  font-weight: bold;
}

div img {
  width: 5rem;
  cursor: pointer;
}
`;

export const Menu = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 20rem;
  /* margin: 0; */
  `;
